<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">manage pools</div>
      </portal>
      
      <ActionOutlinedButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :to="{ name: 'CreatePool'}">
        Create pool
      </ActionOutlinedButton>
      
      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />
      
      <v-spacer />
  
      <SearchField
        v-model="search"
        class="mb-4 mb-sm-15"
        @changedSearch="refresh"
      />
  
      <SelectOnPage
        class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-4 mb-sm-15"
        :readonly="loading"
        @changeOnPage="refresh"
      />
    </div>
    
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options="options"
        :custom-sort="() => items"
        :expanded.sync="expanded"
        item-key="id"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        show-expand
        :loader-height="2"
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
        @update:options="changeOptions"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>
        
        <template #item.name="{ item }">
          <span class="font-normal text-no-wrap">{{ item.name }}</span>
        </template>
        
        <template #item.country_id="{ item }">
          <span class="font-normal text-no-wrap">
            {{ getCountryNameById(item.country_id) }}
          </span>
        </template>
  
        <template #item.pool_size="{ item }">
          <span class="font-normal text-no-wrap">{{ item.numbers.length }} / {{ item['pool_size'] }}</span>
        </template>
        
        <template #item.actions="{ item }">
          <TooltipAction bottom message="Edit">
            <template #activator>
              <v-icon class="mr-6" @click="openEditPool(item)">$pencilIcon</v-icon>
            </template>
          </TooltipAction>
  
          <TooltipAction bottom message="Detach and unrent numbers">
            <template #activator>
              <v-icon class="mr-6" @click="openDetachAllNumbersDialog(item)">$linkOffIcon</v-icon>
            </template>
          </TooltipAction>
  
          <TooltipAction bottom message="Auto buy numbers">
            <template #activator>
              <v-icon class="mr-6" @click="openAutoBuyDialog(item)">$phoneIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Delete">
            <template #activator>
              <v-icon @click="openDeletePoolDialog(item)">$deleteIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
        
        <template #item.data-table-expand="{ expand, isExpanded }">
          <v-icon :disabled="loading" @click="expand(!isExpanded)">$cornerDown</v-icon>
        </template>
        
        <template #expanded-item="{ headers, item }">
          <td class="cardChildBg pa-0" :colspan="headers.length">
            <v-expand-transition>
              <v-data-table
                class="elevation-0 cardChildBg"
                :headers="headersChild"
                :items="item.numbers"
                item-key="slug"
                outlined
                hide-default-footer
                disable-pagination
                :header-props="{ sortIcon: '$sortDownIcon' }"
                :mobile-breakpoint="0"
              >
                <template #item.number="{ item }">
                  +{{ item.number }}
                </template>
                
                <template #item.country="{ item }">
                  <span class="font-normal text-no-wrap">
                    {{ item.country ? item.country.name : '-//-' }}
                  </span>
                </template>
                
                <template #item.allocated="{ item }">
                  <span class="text-no-wrap">
                    {{ item.allocated }}
                  </span>
                </template>
                
                <template #item.renew="{ item }">
                  <span class="font-normal text-no-wrap">
                    {{ getRenew(item['last_charge']) }}
                  </span>
                </template>
                
                <template #item.status_id="{ item }">
                  <SwitchSm
                    :value="getStatus(item)"
                    @input="changeStatus(item)"
                    :loading="loadingStatus"
                    bgColor="switcherYellow"
                  />
                </template>
                
                <template #item.actions="{ item }">
                  <TooltipAction bottom message="Detach">
                    <template #activator>
                      <v-icon @click="openDetachDialog(item)">$linkOffIcon</v-icon>
                    </template>
                  </TooltipAction>
                </template>
              </v-data-table>
            </v-expand-transition>
          </td>
        </template>
      </v-data-table>

      <TablePagination
        :loading="loading"
        :dataPagination="dataPagination"
        @input="changePage"
      />

      <div :class="dataPagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    </div>
    
    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      :unrentNumbers="unrentNumbers"
      @actionSuccess="actionSuccess"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { Types as indexTypes } from '@/store'
  
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import SearchField from '@/components/inputs/SearchField'
import SelectOnPage from '@/components/select/SelectOnPage'
import SwitchSm from '@/components/controls/SwitchSm'
import TooltipAction from '@/components/tooltip/TooltipAction'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import TablePagination from '@/components/pagination/TablePagination'

import { getPools, detachNumbersFromPool, deletePool, autoBuyNumbersForPool, detachAllNumbersFromPool } from '@/api/pool-routes'
import { updateNumber } from '@/api/number-routes'
import { getOnPage, changeItemsAfterUpdate, renewDate, parsePaginationData } from '@/helper/app-helper'

export default {
  name: 'ManagePools',
  components: { ActionOutlinedButton, RefreshButton, SearchField, SelectOnPage, SwitchSm, TooltipAction, DialogConfirm, TablePagination },
  data: () => ({
    loading: false,
    loadingAction: false,
    loadingStatus: false,
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1
    },
    options: {},
    headers: [
      { text: 'Name', value: 'name', align: 'left', sortId: 'byName' },
      { text: 'Country', value: 'country_id', sortId: 'byCountry' },
      { text: 'Closed Browser Delay', value: 'closed_delay', align: 'center', sortId: 'byClosedBrowserDelay' },
      { text: 'Idle limit', value: 'idle_limit', align: 'center', sortId: 'byIdleLimit' },
      { text: 'Pool size', value: 'pool_size', align: 'center', sortId: 'byPoolSize' },
      { text: 'Actions', value: 'actions', sortable: false, width: 180, align: 'right' },
    ],
    headersChild: [
      { text: '', value: '', sortable: false, width: 65 },
      { text: 'Number', value: 'number' },
      { text: 'Country', value: 'country' },
      { text: 'Region', value: 'region' },
      { text: 'Allocated', value: 'allocated' },
      { text: 'Renew', value: 'renew', sortable: false },
      { text: 'Status', value: 'status_id', align: 'center' },
      { text: 'Actions', value: 'actions', width: 40, sortable: false, align: 'center' }
    ],
    expanded: [],
    confirmDialog: false,
    unrentNumbers: false,
    selectedItem: null,
    actionName: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    serverSorting: null,
    search: null,
  }),
  computed: {
    ...mapGetters({
      getCountryNameById: indexTypes.getters.GET_COUNTRY_NAME_BY_ID,
    }),
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  methods: {
    changeOptions(options) {
      if (!this.loading) {
        this.options = Object.assign({}, options)
      }
    },
    async loadPools() {
      this.loading = true

      const { success, payload, message } = await getPools({
        page: this.dataPagination.page,
        onPage: getOnPage(),
        search: this.search,
        ...this.serverSorting,
      })

      if (success) {
        this.dataPagination = parsePaginationData(payload)
        this.items = payload.data
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    getStatus(item) {
      return item['status_id'] === 1
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadPools()
      }
      this.$vuetify.goTo(0)
    },
    async changeStatus(number) {
      this.loadingStatus = true

      const { success, payload, message } = await updateNumber({
        numberId: number.id,
        formData: { 'toggle_status': true },
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.changeUpdatedNumber(payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingStatus = false
    },
    actionSuccess(params) {
      this[this.actionName](params)
    },
    changeUpdatedNumber(updatedNumber) {
      this.items = this.items.map(item => {
        if (item.numbers.some(number => number.id === updatedNumber.id)) {
          item.numbers = changeItemsAfterUpdate(item.numbers, updatedNumber, 'id')
        }
        return item
      })
    },
    openEditPool(pool) {
      this.$router.push({ name: 'DetailPool', params: { id: pool.id }})
    },
    openDetachDialog(number) {
      this.actionName = 'goToDetachNumber'
      this.selectedItem = number
      this.dialogTitle = 'Detach number'
      this.dialogSubTitle = `+${number.number}`
      this.dialogMessage = 'Are you sure you want to detach this number from the pool?'
      this.unrentNumbers = true
      this.confirmDialog = true
    },
    async goToDetachNumber(params) {
      this.loadingAction = true

      const { success, message } = await detachNumbersFromPool({
        'pool_id': this.selectedItem['number_pool_relate']['number_pool_id'],
        numbers: [this.selectedItem.id],
        ...params
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message ? message : 'Number was detached!' })
        this.confirmDialog = false
        this.removeNumberAfterDetach()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    removeNumberAfterDetach() {
      this.items = this.items.map(item => {
        if (item.numbers.some(number => number.id === this.selectedItem.id)) {
          item.numbers = item.numbers.filter(number => number.id !== this.selectedItem.id)
        }
        return item
      })
    },
    openDetachAllNumbersDialog(pool) {
      this.actionName = 'goToDetachAllNumbers'
      this.selectedItem = pool
      this.dialogTitle = 'Detach numbers from pool'
      this.dialogSubTitle = pool.name
      this.dialogMessage = 'Are you sure you want to detach all numbers from the pool?'
      this.unrentNumbers = true
      this.confirmDialog = true
    },
    async goToDetachAllNumbers(params) {
      this.loadingAction = true
      const { success, message } = await detachAllNumbersFromPool({
        poolId: this.selectedItem.id,
        params
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.refresh()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    openAutoBuyDialog(pool) {
      this.actionName = 'goToAutoBuy'
      this.selectedItem = pool
      this.dialogTitle = 'Auto buy numbers for pool'
      this.dialogSubTitle = `${pool.name}`
      this.dialogMessage = 'Are you sure you want to auto buy numbers for this pool?'
      this.unrentNumbers = false
      this.confirmDialog = true
    },
    openDeletePoolDialog(pool) {
      this.actionName = 'goToDeletePool'
      this.selectedItem = pool
      this.dialogTitle = 'Delete number pool'
      this.dialogSubTitle = `${pool.name}`
      this.dialogMessage = 'Are you sure you want to delete this number pool?'
      this.unrentNumbers = false
      this.confirmDialog = true
    },
    async goToAutoBuy() {
      this.loadingAction = true

      const { success, message } = await autoBuyNumbersForPool(this.selectedItem.id)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.refreshDataAfterDelete()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    async goToDeletePool() {
      this.loadingAction = true

      const { success, message } = await deletePool(this.selectedItem.id)

      if (success) {
        this.$notify({ type: 'success-bg', text: message ? message : 'Pool was deleted!' })
        this.confirmDialog = false
        this.refresh()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    refreshDataAfterDelete() {
      if (this.items.length === 1 && this.dataPagination.page !== 1) {
        --this.dataPagination.page
      }

      this.loadPools()
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadPools()
    },
    getRenew(lastCharge) {
      return renewDate(lastCharge)
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'asc' : 'desc' }
        } else {
          this.serverSorting = null
        }
        this.loadPools()
      },
    },
  },
}
</script>
